nav {
    height: 8vh;
        display: flex;
    justify-content: space-around;
    padding: 16px;
        align-items: center;
    background: #12151acb;
    /* box-shadow: 0px 0px 10px 0px #D5A149; */
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 100;
    /* box-shadow: 0px 0px 10px rgb(126, 0, 0); */
}

.logo {
    color: #FFF;
    font-size: 22px;
    width: 20%;
    margin-right: 5rem;
}

.navLogo{
    width: 200px;
    -webkit-user-select: none;
}

ul {
    list-style: none;
}

ul li {
    display: inline-block;
}

ul li a {
    text-decoration: none;
    color: whitesmoke;
    padding: 8px 20px;
    border-radius: 7px;
    transition: all .3s ease;
    font-size: 15px;
    font-family: montserrat, sans-serif;
    font-weight: 400;
    font-style: normal;
}

/* ul li a:hover,
ul li a.active{
    color: #D5A149;
}
ul li:hover{
    border-bottom: 1px solid #D5A149;
} */

#icon {
    background-color: transparent;
    padding: 5px 7px 3px 7px;
    border-radius: 4px;
    cursor: pointer;
    transition: all .3s ease;
}

#icon svg {
    color: #FFC700;
    height: 28px;
    transition: all .3s ease;
}


#checkbox,
#icon {
    display: none;
}

.iconsnav{
    padding: 0 10px!important;
}

.linkedinIcon{
    font-size: 25px;
}

nav ul li a,
nav ul li a:after,
nav ul li a:before {
  transition: all .5s;
}
nav ul li a:hover {
  color: #FFC700;
}

nav.stroke ul li a,
nav.fill ul li a {
  position: relative;
}
nav.stroke ul li a:after,
nav.fill ul li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: #FFC700;
  height: 1px;
}
nav.stroke ul li a:hover:after {
  width: 100%;
}

nav.fill ul li a {
  transition: all 2s;
}

nav.fill ul li a:after {
  text-align: left;
  content: '.';
  margin: 0;
  opacity: 0;
}
nav.fill ul li a:hover {
  color: #fff;
  z-index: 1;
}
nav.fill ul li a:hover:after {
  z-index: -10;
  animation: fill 1s forwards;
  -webkit-animation: fill 1s forwards;
  -moz-animation: fill 1s forwards;
  opacity: 1;
}


@-webkit-keyframes fill {
    0% {
      width: 0%;
      height: 1px;
    }
    50% {
      width: 100%;
      height: 1px;
    }
    100% {
      width: 100%;
      height: 100%;
      background: #333;
    }
  }

  @media screen and (min-width: 824px){
    #ul-nav{
        display: flex;
      }
  }

@media screen and (max-width: 824px) {

    nav {
        display: flex;
        justify-content: flex-end;
        height: 7vh;
        background-color: #12151A;
        border-bottom: 2px solid #ABB8C3;
    }

    ul {
        position: absolute;
        width: 100%;
        height: calc(100vh - 71px);
        background-color: #12151A;
        left: 0;
        top: 0;
        text-align: center;
        display: none;
        margin-top: 85px;
        padding: 2%;
    }

    ul li {
        display: block;
        padding: 10px 15px;
    }

    ul li a {
        display: block;
        padding: 10px 0;
    }

    #icon {
        display: block;
        margin-left: 25%;
    }

    #checkbox:checked~ul {
        display: block;
    }
    .navLogo{
        width: 200px;
    }

    .row-responsive{
        display: flex;
        justify-content: center;
    }
    .row-responsive li{
        padding: 5px;
    }
    
}

@media screen and (max-width: 500px) and (min-width:380px){
    .navLogo{
        width: 150px;
    }
}

@media screen and (max-width: 650px) {
/* 
    .navLogo{
        width: 60%;
    } */
}

@media screen and (max-width: 1000px) {
/* 
    .navLogo{
        width: 40%;
    } */
}
.header-wrapper{
    width: 90%;
    height: 100vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header-upper{
    width: 50%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 8rem;
}

.hr-row{
    height: 2px;
    width: 70%;
    background: #ABB8C3;
    background: linear-gradient(90deg, rgba(213, 162, 73, 0) 1%, #ABB8C3 25%, #ABB8C3 79%, rgba(213, 162, 73, 0) 100%);
    border: none;    
    margin-top: 2.5rem;
}

.hr-row-big{
    width: 100%;
    height: 2px;
    background: #ABB8C3;
    background: linear-gradient(90deg, rgba(213, 162, 73, 0) 1%, #ABB8C3 25%, #ABB8C3 79%, rgba(213, 162, 73, 0) 100%);
    border: none; 
    margin-top: 2.5rem;
    margin-bottom: 0;
}

.header-upper img{
    max-width: 250px;
    -webkit-user-drag: none;
}

.header-upper img::selection{
    user-select: none;
}

.header-down{
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #ffffff;
    font-family: montserrat, sans-serif;
    font-weight: 300;
    font-style: normal;
}

.header-down-name{
    font-family: montserrat-alternates, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 2rem;
    color: #FFC700;
}

.header-p{
    font-size: 1.3rem;
}

.header-button{
    width: 14rem;
    height: 7vh;
    border-radius: 15px;
    /* border: 3px solid #D5A149; */
    color: #FFFFFF; 
    

    background-color: #FFC700;
    margin: 1rem auto;
    border: none;
}

.header-button a{
    color: #ffffff;
    text-decoration: none;
    font-weight: 700;
    font-size: 1.2rem;
}

.header-button:hover{
    box-shadow: 0px 0px 10px 0px #FFC700;
    transition: 0.3s;
    cursor: pointer;
    border: 0;
}

@media screen and (max-width:1200px) {
    .header-wrapper{
        width: 90%;
        height: auto;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .hr-row{
        width: 90%;
    }
}
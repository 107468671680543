#programming-section{
    width: 100%;
    height: auto;
    /* background-color: #12151A; */
    background-color: transparent;

}
.programming-wrapper{
    width: 90%;
    height: 80vh;
    margin: auto;
}

.programming-container{
    width: 80%;
    height: 90%;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.programming-header{
    font-family: montserrat-alternates, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.3rem;
    color: #FFC700;
    display: flex;
}

.programming-content{
    width: 90%;
    height: 80%;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.language-fields{
    width: 37%;
    height: 80%;
    background-color: #292D30;
    border-radius: 33px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: montserrat, sans-serif;
    font-weight: 600;
    font-style: normal;
    color: #ffffff;
    font-size: 1.5rem;
}

.language-fields img{
    width: 150px;
    -webkit-user-drag: none;
}

img::selection{
    color: none;
    background-color: none;
    user-select: none;
}

.language-p{
    font-family: montserrat, sans-serif;
    font-weight: 300;
    font-style: normal;
    color: #ffffff;
    font-size: 1.2rem;
    margin: 10px;
}

@media screen and (max-width: 1300px) and (min-width: 1050px) {
    .language-fields{
        width: 45%;
        height: 85%;
    }
}


@media screen and (max-width: 1049px) and (min-width: 850px) {
    .language-fields{
        width: 48%;
        height: 85%;
    }

    .language-h{
        font-size: 1.25rem;
    }
    .language-p{
        font-size: 1.1rem;
    }

    .language-fields img{
        width: 120px;
    }

}

@media screen and (max-width: 849px) and (min-width: 821px) {
    .language-fields{
        width: 48%;
        height: 85%;
    }

    .language-h{
        font-size: 1.2rem;
    }
    .language-p{
        font-size: 1rem;
    }

    .language-fields img{
        width: 110px;
    }

}

@media screen and (max-width: 820px) and (min-width: 481px) {

    .programming-wrapper{
        width: 90%;
        height: auto;
        margin: auto;
    }

    .programming-content{
        width: 100%;
        height: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .programming-header{
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
    }

    .language-fields{
        width: 90%;
        height: 45%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 1.3rem;
        margin-bottom: 2rem;
        margin-top: 2rem;
    }

    .language-fields img{
        width: 150px;
        margin: 1.5rem;
    }

    .language-p{
        margin-bottom: 2rem;
    }
}

@media screen and (max-width: 480px) and (min-width: 320px) {

    .programming-wrapper{
        width: 95%;
        height: auto;
        margin: auto;
    }

    .programming-content{
        width: 100%;
        height: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .programming-header{
        font-size: 1rem;
        display: flex;
        justify-content: center;
    }

    .language-fields{
        width: 100%;
        height: 45%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 1.3rem;
        margin-bottom: 2rem;
        margin-top: 2rem;
    }

    .language-fields img{
        width: 150px;
        margin: 1.5rem;
    }

    .language-p{
        margin-bottom: 2rem;
    }
}


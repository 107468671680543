#projects-section{
    width: 100%;
    height: auto;
    /* background-color: #12151A; */
    background-color: transparent;

}
.projects-wrapper{
    width: 90%;
    height: 115vh;
    margin: auto;
}

.projects-container{
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.projects-header{
    font-family: montserrat-alternates, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.3rem;
    color: #FFC700;
    display: flex;
}

.projects-content{
    width: 90%;
    height: 80%;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
   
}


.projects-fields1{
    width: 37%;
    height: 80%;
    background-color: #292D30;
    border-radius: 33px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    font-family: montserrat, sans-serif;
    font-weight: 600;
    font-style: normal;
    color: #ffffff;
    font-size: 1.5rem;
    background-image: url("../../assets/twig.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 1rem;
}

.projects-fields1-content{
    background-color: #000000c2;
    height: 35%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom-left-radius: 33px;
    border-bottom-right-radius: 33px;
}

.projects-fields1-content:hover {
    border-radius: 33px;
    cursor: pointer;
}

.projects-fields1:hover .projects-fields1-content{
    height: 100%;
    border-radius: 33px;
    transition: 0.5s ease-in;
}

.projects-fields1:hover a{
    display: block;
}

.projects-fields1:hover .description-p{
    display: block;
}


.projects-fields1-content a{
    color: #FFC700;
    font-size: large;
    text-decoration:none;
    display: none;
    transition-delay: 0.1s;
}

.projects-fields1-content a:hover{
    color: #832724;
    transition: 0.3s;
}


.projects-fields img{
    width: 100%;
    height: 100%;
    border-radius: 33px;
}

.project-description{
    height: 30%;
    width: 100%;
    background-color: #000000;   
    border-bottom-left-radius: 33px;
    border-bottom-right-radius: 33px;
    display: flex;
    flex-direction: column;
}

.description-header{
    margin-top: 2%;
    font-size: 100%;
}

.description-p{
    margin-top: 0;
    font-size: 60%;
    font-weight: 300;
    display: none;
    transition-delay: 0.1s;

}

.projects-p{
    font-family: montserrat, sans-serif;
    font-weight: 300;
    font-style: normal;
    color: #ffffff;
    font-size: 1.2rem;
    margin: 10px;
}

@media screen and (max-width: 1300px) and (min-width: 1050px) {
    .projects-fields1{
        width: 45%;
        height: 85%;
    }
}


@media screen and (max-width: 1049px) and (min-width: 850px) {
    .projects-fields1{
        width: 48%;
        height: 85%;
    }

    .projects-h{
        font-size: 1.25rem;
    }
    .projects-p{
        font-size: 1.1rem;
    }

    .projects-fields img{
        width: 120px;
    }

}

@media screen and (max-width: 849px) and (min-width: 821px) {
    .projects-fields1{
        width: 48%;
        height: 85%;
    }

    .projects-h{
        font-size: 1.2rem;
    }
    .projects-p{
        font-size: 1rem;
    }

}
 
@media screen and (max-width: 820px) and (min-width: 481px) {

    .projects-wrapper{
        width: 90%;
        height: auto;
        margin: auto;
    }

    .projects-content{
        width: 100%;
        height: 100vh;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .projects-header{
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
    }

    .projects-fields1{
        width: 90%;
        height: 45%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        font-size: 1.3rem;
        margin-bottom: 2rem;
        margin-top: 2rem;
    }


    .projects-p{
        margin-bottom: 2rem;
    }
}

@media screen and (max-width: 480px) and (min-width: 320px) {

    .projects-wrapper{
        width: 95%;
        height: auto;
        margin: auto;
    }

    .projects-content{
        width: 100%;
        height: 100vh;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .projects-header{
        font-size: 1rem;
        display: flex;
        justify-content: center;
    }

    .projects-fields1{
        width: 100%;
        height: 45%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        font-size: 1.3rem;
        margin-bottom: 2rem;
        margin-top: 2rem;
    }

    .projects-p{
        margin-bottom: 2rem;
    }
}

